import { Component, OnInit, OnDestroy } from '@angular/core';
import { SpaserverService } from '../../spaserver.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DialogService } from '@wellext/kit';
import { CheckinCheckoutService } from '@wellext/spa-api';

@Component({
  selector: 'wlx-check-out-payment',
  templateUrl: './check-out-payment.component.html',
  styleUrls: ['./check-out-payment.component.scss'],
})
export class CheckOutPaymentComponent implements OnInit, OnDestroy {
  private s1?: Subscription;
  private s2?: Subscription;

  constructor(
    public spaService: SpaserverService,
    private router: Router,
    private dialogService: DialogService,
    private checkOutService: CheckinCheckoutService
  ) {}

  ngOnDestroy() {
    this.s1?.unsubscribe();
    this.s2?.unsubscribe();
  }

  async ngOnInit() {
    if (!this.spaService.activeSession) await this.cancel();
    // this.s2 = this.spaService.onPaymentCompleted.subscribe(() => {
    //   this.spaService.printTransaction(false);
    // });
    this.s1 = this.spaService.onPaymentCanceled.subscribe(e => {
      this.dialogService.showErrorDialog(e);
      setTimeout(() => {
        this.spaService.activeSession.transaction = undefined;
        // this.cancel()
      }, 1000 * 5); //todo
    });

    if (!this.spaService.activeSession?.selectedItems.length) {
      await this.router.navigate(['/check-out-accepted']);
      return;
    }

    //await this.startPayment();

    //todo payment selection
    // await this.startPayment();
  }

  async startPayment() {
    await this.spaService.completeCheckout();
  }

  async cancel() {
    await this.spaService.resetPOS();
    await this.router.navigate(['/check-out']);
  }

  async completeCheckout(print: boolean) {
    if (print) {
      await this.spaService.printTransaction(false);
    }
    //todo checkout

    //this.checkOutService.checkoutGuest(this.spaService.getActiveSession()?.code);
    await this.router.navigate(['/check-out-accepted']);
  }
}
