import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CheckOutPaymentProviderRoutingModule } from './check-out-payment-provider-routing.module';
import { CheckOutPaymentProviderComponent } from './check-out-payment-provider.component';
import { ButtonModule, HeadingModule, InputModule } from '@wellext/kit';
import { CheckOutCouponScanModule } from '../check-out-coupon-scan/check-out-coupon-scan.module';

@NgModule({
  declarations: [CheckOutPaymentProviderComponent],
  imports: [
    CommonModule,
    CheckOutPaymentProviderRoutingModule,
    ButtonModule,
    HeadingModule,
    InputModule,
    CheckOutCouponScanModule,
  ],
  exports: [CheckOutPaymentProviderComponent],
})
export class CheckOutPaymentProviderModule {}
