<svg class="logo" viewBox="0 0 68 37" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M22.4913 30.6276C21.0116 31.1614 19.4004 31.4617 17.7234 31.4617C16.0135 31.4617 14.4023 31.128 12.9555 30.4941C10.7524 29.5599 8.87814 27.9585 7.56286 25.9901C6.24758 24.0216 5.45841 21.6195 5.45841 19.0171V4.07031H0V19.0171C0 21.486 0.49323 23.8548 1.38104 26.0234C2.72921 29.2597 4.96518 31.9955 7.79304 33.9305C10.6209 35.8656 14.0406 37 17.6905 37C20.8143 37 23.8395 36.2326 26.5029 34.8647C24.9574 33.6636 23.6093 32.229 22.4913 30.6276Z"
    fill="#BCA090"
  />
  <path
    d="M50.8685 7.64016V19.0171C50.8685 20.752 50.5397 22.3868 49.9149 23.8548C48.9942 26.0901 47.4159 27.9918 45.4759 29.3264C43.5358 30.6609 41.1683 31.4616 38.6364 31.4616C36.9265 31.4616 35.3153 31.128 33.8685 30.4941C33.4739 30.3273 33.0794 30.1271 32.6848 29.8935C31.0736 28.9927 29.6925 27.7249 28.6074 26.1902C28.5745 26.1235 28.5088 26.0567 28.443 25.99C27.1277 23.9882 26.3385 21.6194 26.3385 19.0504V4.10364H20.8801V19.0171C20.8801 21.486 21.3734 23.8548 22.2612 26.0234C22.8202 27.3579 23.5436 28.6257 24.3656 29.7601C25.4836 31.2948 26.8647 32.6293 28.4101 33.7303C28.5088 33.7971 28.5745 33.8638 28.6732 33.9305C31.501 35.8656 34.9207 36.9999 38.5707 36.9999C41.0039 36.9999 43.3385 36.4995 45.4759 35.5987C48.6654 34.2308 51.3618 31.9621 53.2689 29.0928C55.1761 26.2236 56.2941 22.7538 56.2941 19.0504V9.67532C55.6364 9.17487 54.913 8.74115 54.1567 8.40752C53.1374 7.97379 52.0523 7.70688 50.8685 7.64016Z"
    fill="#BCA090"
  />
  <path
    d="M66.5203 10.9766C65.1721 7.74031 62.9362 5.00451 60.1083 3.06943C57.2805 1.13436 53.8607 0 50.2108 0C45.147 0 40.412 1.96844 36.9594 5.27142C35.2166 6.93959 33.8027 8.90803 32.8162 11.1434C31.8298 13.3787 31.2708 15.881 31.2708 18.4833C31.2708 20.3183 30.8762 22.0198 30.1857 23.5546C30.087 23.7881 29.9555 24.0216 29.824 24.2552C29.9226 24.422 30.0213 24.6222 30.1528 24.789C31.1064 26.2236 32.3888 27.3913 33.9013 28.1921C34.3946 27.4247 34.7892 26.6573 35.1837 25.8233C36.1702 23.5879 36.7292 21.0857 36.7292 18.4833C36.7292 16.6483 37.1238 14.9468 37.8143 13.4121C38.8336 11.11 40.5764 9.10821 42.7466 7.70694C44.9168 6.30568 47.5145 5.50496 50.2766 5.50496C50.5068 5.50496 50.7369 5.50496 50.9342 5.53832C52.381 5.60505 53.7621 5.93868 55.0445 6.50586C55.5048 6.70604 55.9652 6.93959 56.3926 7.20649C58.0039 8.14067 59.3849 9.4752 60.4371 11.0099C61.7524 13.0117 62.5416 15.3805 62.5416 17.9495V32.8963H68V17.9829C67.9342 15.514 67.4081 13.1452 66.5203 10.9766Z"
    fill="#BCA090"
  />
</svg>
<div class="content">
  <div class="content-content">
    <img class="poster-image" src="/assets/renderings/qr.png" alt="poster image" />
    <br />
    <br />
    <br />
    <wlx-heading>
      <wlx-heading-lead x1> Gutscheincode scannen... </wlx-heading-lead>
    </wlx-heading>
    <br />
    <div class="code-note">
      Bitte scanne deinen Gutscheincode um ihn <br />
      einzulösen.
    </div>
    <br />
  </div>
</div>
<div class="payment-method-navigate">
  <wlx-button> Abbrechen </wlx-button>
</div>
