import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionPrintComponent } from './transaction-print.component';
import { ButtonModule, HeadingModule } from '@wellext/kit';

@NgModule({
  declarations: [TransactionPrintComponent],
  imports: [CommonModule, HeadingModule, ButtonModule],
  exports: [TransactionPrintComponent],
})
export class TransactionPrintModule {}
