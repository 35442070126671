import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckOutPaymentRoutingModule } from './check-out-payment-routing.module';
import { CheckOutPaymentProviderModule } from '../check-out-payment-provider/check-out-payment-provider.module';
import { TransactionPrintModule } from '../transaction-print/transaction-print.module';
import { TransactionProcessingModule } from '../transaction-processing/transaction-processing.module';
import { CheckOutPaymentComponent } from './check-out-payment.component';

@NgModule({
  declarations: [CheckOutPaymentComponent],
  imports: [
    CommonModule,
    CheckOutPaymentRoutingModule,
    TransactionProcessingModule,
    TransactionPrintModule,
    CheckOutPaymentProviderModule,
  ],
  exports: [CheckOutPaymentComponent],
})
export class CheckOutPaymentModule {}
