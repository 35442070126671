import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'wlx-transaction-print',
  templateUrl: './transaction-print.component.html',
  styleUrls: ['./transaction-print.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionPrintComponent {
  @Output()
  next = new EventEmitter<boolean>();
}
