import {
  Component,
  EventEmitter,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { SpaserverService } from '../../spaserver.service';
import { DialogService } from '@wellext/kit';

@Component({
  selector: 'wlx-check-out-payment-provider',
  templateUrl: './check-out-payment-provider.component.html',
  styleUrls: ['./check-out-payment-provider.component.css'],
})
export class CheckOutPaymentProviderComponent {
  @Output()
  startPayment = new EventEmitter();

  @ViewChild('couponModal')
  couponModal: TemplateRef<any>;

  constructor(
    public spaService: SpaserverService,
    private dialogService: DialogService,
    private viewContainerRef: ViewContainerRef
  ) {}

  calcBasketTotal(): number {
    return this.spaService.activeSession?.selectedItems
      .map(item => item.quantity * this.spaService.activeSession?.getItemPrice(item.id))
      .reduce((a, b) => a + b, 0);
  }

  showCouponDialog() {
    this.dialogService.open(this.viewContainerRef, this.couponModal);
  }
}
