import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionProcessingComponent } from './transaction-processing.component';
import { ButtonModule, HeadingModule } from '@wellext/kit';

@NgModule({
  declarations: [TransactionProcessingComponent],
  imports: [CommonModule, HeadingModule, ButtonModule],
  exports: [TransactionProcessingComponent],
})
export class TransactionProcessingModule {}
