<!--<p>check-out-payment works!</p>-->

<wlx-check-out-payment-provider
  (startPayment)="startPayment()"
  *ngIf="!spaService.activeSession.transaction || spaService.activeSession?.transaction?.status === 'FAILED'"
></wlx-check-out-payment-provider>
<wlx-transaction-processing
  [debugMessage]="spaService.posDebugMessage"
  *ngIf="spaService.activeSession?.transaction?.status === 'PENDING'"
></wlx-transaction-processing>
<wlx-transaction-print
  (next)="completeCheckout($any($event))"
  *ngIf="spaService.activeSession?.transaction?.status === 'COMPLETED'"
></wlx-transaction-print>
