import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckOutPaymentComponent } from './check-out-payment.component';

const routes: Routes = [{ path: '', component: CheckOutPaymentComponent }];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
})
export class CheckOutPaymentRoutingModule {}
