import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckOutCouponScanComponent } from './check-out-coupon-scan.component';
import { ButtonModule, HeadingModule } from '@wellext/kit';

@NgModule({
  declarations: [CheckOutCouponScanComponent],
  imports: [CommonModule, HeadingModule, ButtonModule],
  exports: [CheckOutCouponScanComponent],
})
export class CheckOutCouponScanModule {}
