import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'wlx-transaction-processing',
  templateUrl: './transaction-processing.component.html',
  styleUrls: ['./transaction-processing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionProcessingComponent {
  @Input()
  debugMessage: string;
}
